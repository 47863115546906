import { useCssVar } from "@vueuse/core";
import Color from "color";
import { GlobalThemeOverrides } from "naive-ui";
import { ComputedRef } from "vue";
import { oneWaySync } from "../util/reactivity";
import { ColorScheme, FontOptions, RoundnessOptions, Theme } from "../util/theme";
import { onLogout } from "../util/logout";
import { pinia } from "./pinia";

type ThemeStoreState = {
  activeTheme: Theme | null;
  override: Theme | null;
  themes: Theme[];
  teamTheme: Theme | null;
};

type ThemeStoreActions = {
  availableThemes: () => ComputedRef<Theme[]>;
  theme: () => ComputedRef<Theme>;
  setTheme: (name: string) => void;
  background: () => ComputedRef<string>;
  font: () => ComputedRef<string>;
  roundness: () => ComputedRef<string>;
  btnRoundness: () => ComputedRef<string>;
  themeOverrides: () => ComputedRef<GlobalThemeOverrides>;
  init: () => void;
};

export const useThemeStore = defineStore<string, ThemeStoreState, {}, ThemeStoreActions>("theme", {
  state: () => ({
    activeTheme: null as Theme | null,
    override: null as Theme | null,
    themes: [] as Theme[],
    teamTheme: null as Theme | null,
  }),
  actions: {
    availableThemes(): ComputedRef<Theme[]> {
      return computed(() => {
        return [defaultDarkTheme].concat(this.themes);
      });
    },
    theme(): ComputedRef<Theme> {
      if (!this.activeTheme) {
        this.activeTheme = defaultDarkTheme;
      }
      return computed(() => {
        return this.override ?? this.teamTheme ?? this.activeTheme!;
      });
    },
    setTheme(name: string) {
      const theme = this.availableThemes().value.find((t) => t.name == name);
      if (theme) {
        this.activeTheme = theme;
      }
    },
    background() {
      return computed(() => {
        if (!this.theme().value.backgroundGradient) {
          return this.theme().value.colors.background1;
        }
        return `radial-gradient(50% 50% at 50% 50%, ${this.theme().value.colors.background2} 0%, ${
          this.theme().value.colors.background1
        } 100%)`;
      });
    },
    font() {
      return computed(() => {
        let availableFonts = Object.keys(FontOptions);
        availableFonts = availableFonts.slice(availableFonts.length / 2);
        return availableFonts[this.theme().value.font];
      });
    },
    roundness() {
      return computed(() => {
        switch (this.theme().value.roundness) {
          case RoundnessOptions.none:
            return "0";
          case RoundnessOptions.minimal:
            return "3px";
          case RoundnessOptions.medium:
            return "6px";
          case RoundnessOptions.extra:
            return "12px";
          default:
            return "0";
        }
      });
    },
    btnRoundness(): ComputedRef<string> {
      return computed(() => {
        switch (this.theme().value.roundness) {
          case RoundnessOptions.none:
            return "0";
          case RoundnessOptions.minimal:
          case RoundnessOptions.medium:
          case RoundnessOptions.extra:
            return "999px";
          default:
            return "0";
        }
      });
    },
    themeOverrides(): ComputedRef<GlobalThemeOverrides> {
      const colors = this.theme().value.colors as ColorScheme;
      const buttonPadding = "0 .8em";
      const inputPadding = "0 .8em";
      const tabPadding = ".2em";
      const buttonHeight = "2.2em";
      const inputHeight = "2.2em";
      const menuItemHeight = "2em";
      const checkboxSize = "1.2em";
      const fontSizeHuge = "1.5rem";
      const fontSizeLarge = "1.25rem";
      const fontSize = "1rem";
      const fontSizeSmall = ".875rem";
      const fontSizeTiny = ".75rem";
      return computed(
        () =>
          ({
            common: {
              fontSize,
              fontSizeHuge,
              fontSizeLarge,
              fontSizeMedium: fontSize,
              fontSizeSmall,
              fontSizeTiny,
              borderRadius: this.roundness().value,
              baseColor: colors.background2,
              primaryColor: colors.basic.primary,
              primaryColorHover: hover(colors.basic.primary),
              primaryColorPressed: hover(colors.basic.primary),
              primaryColorSuppl: hover(colors.basic.primary),
              infoColor: colors.secondary,
              infoColorHover: hover(colors.secondary),
              infoColorPressed: hover(colors.secondary),
              infoColorSuppl: hover(colors.secondary),
              successColor: colors.success,
              successColorHover: hover(colors.success),
              successColorPressed: hover(colors.success),
              successColorSuppl: hover(colors.success),
              warningColor: colors.warning,
              warningColorHover: hover(colors.warning),
              warningColorPressed: hover(colors.warning),
              warningColorSuppl: hover(colors.warning),
              errorColor: colors.error,
              errorColorHover: hover(colors.error),
              errorColorPressed: hover(colors.error),
              errorColorSuppl: hover(colors.error),
              textColorBase: colors.text,
              textColor1: colors.text,
              textColor2: colors.text,
              textColor3: colors.text,
              textColorDisabled: hover(colors.text),
              placeholderColor: hover(colors.text),
              placeholderColorDisabled: hover(colors.text),
              iconColor: colors.text,
              iconColorHover: colors.text,
              iconColorPressed: colors.text,
              iconColorDisabled: hover(colors.text),
              dividerColor: colors.borders,
              borderColor: colors.borders,
              closeIconColor: colors.text,
              closeIconColorHover: colors.text,
              closeIconColorPressed: hover(colors.text),
              closeColorHover: colors.foreground2,
              closeColorPressed: hover(colors.foreground2),
              clearColor: colors.text,
              clearColorHover: hover(colors.text),
              clearColorPressed: hover(colors.text),
              progressRailColor: colors.foreground2,
              railColor: colors.basic.primary,
              popoverColor: colors.background2,
              tableColor: colors.background2,
              cardColor: colors.background2,
              modalColor: colors.background2,
              bodyColor: colors.background2,
              tagColor: colors.foreground2,
              avatarColor: colors.foreground2,
              inputColor: colors.foreground1,
              codeColor: colors.foreground2,
              tabColor: colors.foreground2,
              actionColor: colors.foreground1,
              tableHeaderColor: colors.foreground2,
              hoverColor: hover(colors.foreground2),
              tableColorHover: hover(colors.foreground1),
              tableColorStriped: hover(colors.foreground1),
              pressedColor: hover(colors.foreground1),
              inputColorDisabled: hover(colors.foreground1),
              buttonColor2: colors.foreground2,
              buttonColor2Hover: hover(colors.foreground2),
              buttonColor2Pressed: hover(colors.foreground2),
            },
            Button: {
              border: `1px solid ${hover(colors.text)}`,
              borderPrimary: `1px solid ${colors.basic.primary}`,
              borderSecondary: `1px solid ${colors.basic.primary}`,
              borderSuccess: `1px solid ${colors.success}`,
              borderWarning: `1px solid ${colors.warning}`,
              borderError: `1px solid ${colors.error}`,
              borderRadiusLarge: this.btnRoundness().value,
              borderRadiusMedium: this.btnRoundness().value,
              borderRadiusSmall: this.btnRoundness().value,
              borderRadiusTiny: this.btnRoundness().value,
              textColorSuccess: colors.text,
              textColorHoverSuccess: colors.text,
              textColorPressedSuccess: colors.text,
              textColorFocusSuccess: colors.text,
              textColorInfo: colors.secondary,
              textColorHoverInfo: colors.secondary,
              textColorPressedInfo: colors.secondary,
              textColorFocusInfo: colors.secondary,
              textColorDisabledSuccess: hover(colors.text),
              textColorWarning: colors.text,
              textColorHoverWarning: colors.text,
              textColorPressedWarning: colors.text,
              textColorFocusWarning: colors.text,
              textColorDisabledWarning: hover(colors.text),
              textColorError: colors.text,
              textColorHoverError: colors.text,
              textColorPressedError: colors.text,
              textColorFocusError: colors.text,
              textColorDisabledError: hover(colors.text),
              textColorDisabledInfo: hover(colors.text),
              paddingLarge: buttonPadding,
              paddingMedium: buttonPadding,
              paddingSmall: buttonPadding,
              paddingTiny: buttonPadding,
              paddingRoundLarge: buttonPadding,
              paddingRoundMedium: buttonPadding,
              paddingRoundSmall: buttonPadding,
              paddingRoundTiny: buttonPadding,
              heightLarge: buttonHeight,
              heightMedium: buttonHeight,
              heightSmall: buttonHeight,
              heightTiny: buttonHeight,
              colorTertiary: colors.foreground2,
              colorTertiaryHover: hover(colors.foreground2),
              colorTertiaryPressed: hover(colors.foreground2),
              colorTertiaryFocus: hover(colors.foreground2),
              colorTertiaryActive: hover(colors.foreground2),
            },
            Input: {
              heightLarge: inputHeight,
              heightMedium: inputHeight,
              heightSmall: inputHeight,
              heightTiny: inputHeight,
              paddingLarge: inputPadding,
              paddingMedium: inputPadding,
              paddingSmall: inputPadding,
              paddingTiny: inputPadding,
            },
            Tag: {
              colorBordered: colors.foreground2,
            },
            Tabs: {
              tabColorSegment: hover(colors.foreground2),
              colorSegment: hover(colors.foreground1),
              tabPaddingLargeBar: tabPadding,
              tabPaddingMediumBar: tabPadding,
              tabPaddingSmallBar: tabPadding,
            },
            Checkbox: {
              sizeLarge: checkboxSize,
              sizeMedium: checkboxSize,
              sizeSmall: checkboxSize,
              sizeTiny: checkboxSize,
            },
            Card: {
              borderRadius: this.roundness().value,
              paddingMedium: "1rem",
            },
            DatePicker: {
              itemTextColorDisabled: doubleHover(colors.text),
            },
            Dialog: {
              closeBorderRadius: "100%",
              closeColorHover: "transparent",
              closeColorPressed: "transparent",
            },
            Menu: {
              itemHeight: menuItemHeight,
            },
            Form: {
              labelFontSizeLeftLarge: fontSizeLarge,
              labelFontSizeLeftMedium: fontSize,
              labelFontSizeLeftSmall: fontSizeSmall,
              labelFontSizeLeftTiny: fontSizeTiny,
              labelFontSizeTopLarge: fontSizeLarge,
              labelFontSizeTopMedium: fontSize,
              labelFontSizeTopSmall: fontSizeSmall,
              labelFontSizeTopTiny: fontSizeTiny,
            },
          } as GlobalThemeOverrides)
      );
    },
    init() {
      const shape: [string, ComputedRef<string>][] = [
        ["--bg", this.background()],
        ["--font", this.font()],
        ["--roundness", this.roundness()],
        ["--roundness-btn", this.btnRoundness()],
      ];
      const colors: [string, ComputedRef<string>][] = [
        ["--primary", computed(() => this.theme().value.colors.basic.primary)],
        ["--primary-dark", computed(() => Color(this.theme().value.colors.basic.primary).darken(0.6).hexa())],
        ["--primary-light", computed(() => Color(this.theme().value.colors.basic.primary).lighten(0.2).hexa())],
        ["--primary-lighter", computed(() => Color(this.theme().value.colors.basic.primary).lighten(0.45).hexa())],
        ["--secondary", computed(() => this.theme().value.colors.secondary)],
        ["--background", computed(() => this.theme().value.colors.basic.background)],
        ["--text", computed(() => this.theme().value.colors.text)],
        ["--background1", computed(() => this.theme().value.colors.background1)],
        ["--background2", computed(() => this.theme().value.colors.background2)],
        ["--foreground1", computed(() => this.theme().value.colors.foreground1)],
        ["--foreground2", computed(() => this.theme().value.colors.foreground2)],
        ["--borders", computed(() => this.theme().value.colors.borders)],
        ["--success", computed(() => this.theme().value.colors.success)],
        ["--warning", computed(() => this.theme().value.colors.warning)],
        ["--error", computed(() => this.theme().value.colors.error)],
        ["--popup-background-color", computed(() => this.theme().value.colors.background1)],
        ["--background-color", computed(() => this.theme().value.colors.background2)],
        ["--color", computed(() => this.theme().value.colors.text)],
        ["--void", computed(() => Color(this.theme().value.colors.background1).darken(0.5).hexa())],
      ];
      const mapping: [string, ComputedRef<string>][] = [];
      for (const [key, value] of colors) {
        mapping.push([`${key.toLowerCase()}-alpha`, computed(() => alpha(value.value))]);
        mapping.push([`${key.toLowerCase()}-hover`, computed(() => hover(value.value))]);
        mapping.push([`${key.toLowerCase()}-doublehover`, computed(() => doubleHover(value.value))]);
      }
      mapping.push(...shape, ...colors);
      for (const [target, source] of mapping) {
        oneWaySync(useCssVar(target), source);
      }
    },
  },
});

function alpha(color: string) {
  return Color(color).alpha(0.6).hexa();
}

function hover(color: string) {
  return Color(color).alpha(0.5).hexa();
}

function doubleHover(color: string) {
  return Color(color).alpha(0.2).hexa();
}

export const defaultDarkTheme: Theme = {
  name: "Dark",
  colors: new ColorScheme({
    basic: {
      primary: "#457DED",
      background: "#FFF",
      greyScaleBackground: false,
    },
  }),
  roundness: RoundnessOptions.minimal,
  font: FontOptions.Inter,
  backgroundGradient: false,
};

onLogout(() => {
  useThemeStore(pinia).$reset();
});
