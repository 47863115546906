import "./util/monitoring";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/unbounded/300.css";
import "@fontsource/unbounded/400.css";
import "@fontsource/unbounded/500.css";
import "@fontsource/unbounded/600.css";
import "@fontsource/unbounded/700.css";
import { createApp } from "vue";
import "./assets/css/base.scss";
import App from "./App.vue";
import "./middleware/auth.guard";
import "./middleware/transition";
import { router } from "./routes";
import { pinia } from "./stores/pinia";
import { i18n } from "./util/i18n";
import { initHammer } from "./util/hammer";
import { initIntercept } from "@bluepic/privacy";
initIntercept(import.meta.env.V_CONSENT_LS_KEY);

const env = new Map<string, string>();
env.set("VERSION", APP_VERSION);
const omitInProd: (keyof ImportMetaEnv)[] = ["SSR", "DEV", "PROD"];
for (const key in import.meta.env) {
  if (import.meta.env.PROD && omitInProd.includes(key as keyof ImportMetaEnv)) {
    continue;
  }
  env.set(key, (import.meta.env as any)[key]);
}
// @ts-ignore
window.env = Object.fromEntries(env);
// @ts-ignore

export const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(pinia);
initHammer(app);

app.mount("#app");
