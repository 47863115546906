import { ImgRequest } from "./types";

export function initImgListener() {
  const listeners = new Map<string, (reqs: ImgRequest[]) => void>();
  const loadedImages = new Set<string>();
  const failedImages = new Set<string>();
  const imagePerfEntries = new Map<string, PerformanceResourceTiming>();
  const patched = new WeakSet();
  const o = new MutationObserver((entries) => {
    const imgs = document.querySelectorAll("img");
    imgs.forEach((img) => {
      if (patched.has(img)) {
        return;
      }
      img.addEventListener("load", (e) => {
        loadedImages.add(img.src);
      });
      img.addEventListener("error", () => {
        failedImages.add(img.src);
      });
      patched.add(img);
    });
    match();
  });
  o.observe(document, { subtree: true, childList: true });

  const observer = new PerformanceObserver((list) => {
    list.getEntries().forEach((entry) => {
      if (entry instanceof PerformanceResourceTiming && entry.initiatorType === "img") {
        imagePerfEntries.set(entry.name, entry);
      }
    });
    match();
  });
  observer.observe({ type: "resource", buffered: true });

  const imgRequests = new Map<string, ImgRequest>();
  function match() {
    let changed = false;
    for (const src of loadedImages) {
      if (imgRequests.has(src)) continue;
      const perfEntry = imagePerfEntries.get(src);
      if (!perfEntry) continue;
      imgRequests.set(src, {
        location: window.location.href,
        src,
        duration: perfEntry.duration,
        error: false,
      });
      changed = true;
    }
    for (const src of failedImages) {
      if (imgRequests.has(src)) continue;
      imgRequests.set(src, {
        location: window.location.href,
        src,
        error: true,
      });
      changed = true;
    }
    if (changed) {
      listeners.forEach((listener) => {
        listener(Array.from(imgRequests.values()));
      });
    }
  }
  return {
    subscribe(listener: (reqs: ImgRequest[]) => void) {
      const id = Math.random().toString(36).slice(2);
      listeners.set(id, listener);
      return () => {
        listeners.delete(id);
      };
    },
  };
}
