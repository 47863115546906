import { difference } from "lodash";
import axios from "axios";

export interface ConnectionConfig {
  host: string;
  project: string;
  application: string;
  environment: string;
  expectedKeys?: Readonly<string[]> | string[] | ReadonlyArray<string>;
  onUpdate?(key: string, value: any): void;
}

let unsubscribe: () => void;

export async function initRemoteConfig(config: ConnectionConfig) {
  const {
    host,
    project,
    application,
    environment,
    expectedKeys,
    onUpdate,
  } = config;
  const getConfigEndpoint = host + '/application/getconfig';
  // get remote config
  let configSet: any;
  try {
    const response = await axios.post(getConfigEndpoint, {
      project,
      application,
      environments: [environment],
    });
    if (response.status !== 200) {
      throw new Error(`Failed to get config from ${getConfigEndpoint}`);
    }
    configSet = response.data.data;
  } catch (error) {
    throw error;
  }
  // validate keys
  if (expectedKeys) {
    const missingKeys = difference(expectedKeys, Object.keys(configSet));
    if (missingKeys.length > 0) {
      throw new Error(
        `Missing config keys: ${missingKeys.join(', ')}`,
      );
    }
  }
  // init Updates
  if (onUpdate) {
    unsubscribe?.();
    const updateEndpoint = `${host}/record/updates/${project}/${application}`;
    const eventSource = new EventSource(updateEndpoint);
    eventSource.onmessage = (event) => {
      const parsed = JSON.parse(event.data);
      onUpdate(
        parsed['record']['key'],
        parsed['record']['values'].find((v: any) => v['environment'] === environment)?.value,
      );
    }
    unsubscribe = () => {
      eventSource.close();
    }
  }
  return configSet;
}
