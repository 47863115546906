import { router } from "../routes";
import { pinia } from "../stores/pinia";
import { assertSingleVal } from "../util/arrays";
import { logout as doLogout } from "../util/logout";

const allowedRedirects = [
  /^https:\/\/[\w\d\.]+\.bluepic\.((de)|(io))(\/[\w\d]*)*/i,
  /^https:\/\/[\w\d\.]+-staging\.bluepic\.((de)|(io))(\/[\w\d]*)*/i,
  /^https:\/\/[\w\d\.]+\.fellowblue\.dev(\/[\w\d]*)*/i,
  /^(http:\/\/)?localhost:\d+(\/[\w\d]*)*/,
  /^bluepic:\/\/.*/,
  /^capacitor:\/\/.*/,
];

router.beforeEach(async (to, from) => {
  if (to.path.startsWith("/redirect")) return;
  const { returnurl, returnUrl, returnURL, auto, logout, token } = to.query;
  const authStore = useAuthStore(pinia);
  if (!authStore.hydrated) {
    authStore._hydrate?.();
  }
  const _token = assertSingleVal(token);
  if (_token) {
    authStore.init(_token, true);
    delete to.query.token;
  }
  const _logout = assertSingleVal(logout);
  if (_logout !== undefined) {
    console.log("logging out!");
    doLogout();
    delete to.query.logout;
    router.push({
      path: "/onboarding",
      params: to.params,
      query: to.query,
      hash: to.hash,
    });
    return;
  }
  const _returnurl =
    assertSingleVal(returnurl) ||
    assertSingleVal(returnUrl) ||
    assertSingleVal(returnURL);
  if (_returnurl) {
    try {
      //check allowed redirects
      const url = new URL(decodeURIComponent(_returnurl));
      const allowed =
        allowedRedirects.some((regex) => regex.test(url.origin)) ||
        url.protocol === "bluepic:" ||
        url.protocol === "capacitor:";
      if (!allowed) {
        console.error("Redirect not allowed", _returnurl);
        router.push("/");
        return;
      }
      const _auto = assertSingleVal(auto);
      console.log({ _auto });
      if (_auto !== undefined) {
        if (authStore.jwt) {
          return router.push({
            path: "/redirect",
            params: to.params,
            query: to.query,
            hash: to.hash,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  if (!authStore.user) {
    await authStore.tryFetchUser();
  }
  if (authStore.user) {
    if (assertSingleVal(logout)) {
      doLogout();
    }
    if (authStore.user.locked) {
      if (to.path.startsWith("/onboarding/confirm-email")) {
        return;
      }
      if (!authStore.user.email) {
        if (!to.path.startsWith("/onboarding/must-verify-email")) {
          // console.log("redirect to must-verify-email", { to: to.path, from: from.path });
          return router.push({
            path: "/onboarding/must-verify-email",
            params: to.params,
            query: to.query,
            hash: to.hash,
          });
        }
      } else if (!to.path.startsWith("/onboarding/locked")) {
        // console.log("redirect to locked");
        return router.push({
          path: "/onboarding/locked",
          params: to.params,
          query: to.query,
          hash: to.hash,
        });
      }
    }
    try {
      const customerinfo = await authStore.getCustomerInfo();
      if (!customerinfo && !to.path.startsWith("/onboarding")) {
        // console.log("redirect to info");
        return router.push({
          path: "/onboarding/info",
          params: to.params,
          query: to.query,
          hash: to.hash,
        });
      }
    } catch (e) {
      console.error("failed to get customerInfo", e);
    }
  }
  if (to.path.startsWith("/onboarding")) {
    // console.log("onboarding route", to.path);
    if (
      to.path.startsWith("/onboarding/confirm-email") ||
      to.path.startsWith("/onboarding/must-verify-email") ||
      to.path.startsWith("/onboarding/locked")
    ) {
      return;
    }
    if (to.path.startsWith("/onboarding/info")) {
      if (authStore.user) return;
      // console.log("redirect to login");
      return router.push({
        path: "/onboarding/login",
        params: to.params,
        query: { ...to.query, entrypoint: to.path },
        hash: to.hash,
      });
    }
    if (authStore.user) {
      // console.log("redirect to home");
      return router.push({
        path: "/pages/home",
        params: to.params,
        query: to.query,
        hash: to.hash,
      });
    }
  } else {
    if (!authStore.user) {
      // console.log("redirect to login");
      return router.push({
        path: "/onboarding/login",
        params: to.params,
        query: { ...to.query, entrypoint: to.path },
        hash: to.hash,
      });
    }
  }
});
