export function upsert<T>(arr: Array<T>, predicate: (element: T) => boolean, newval: T | Array<T>) {
  if (!Array.isArray(newval)) {
    newval = [newval];
  }
  for (const nv of newval) {
    let index = -1;
    let i = 0;
    for (const el of arr) {
      if (predicate(el)) {
        index = i;
        break;
      }
      i++;
    }
    if (index != -1) {
      arr.splice(index, 1, nv);
    } else {
      arr.push(nv);
    }
  }
}

export function assertSingleVal<T>(value: T | Array<T>): T | null | undefined {
  if (!value) return value;
  if (Array.isArray(value)) {
    if (value.length === 0) return undefined;
    return value[0];
  }
  return value;
}

export function assertArray<T>(value: T | Array<T>): Array<T> {
  if (value === undefined || value === null) return [];
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
}
