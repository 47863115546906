<template>
  <div class="b-btn-blind">
    <div class="bg"></div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .b-btn-blind {
    @apply cursor-pointer relative grid place-content-center p-1 rounded-full;
    .bg {
      @apply absolute inset-0 rounded-full;
      background-color: var(--text);
      opacity: 0;
      transition: opacity ease-in-out;
      transition-duration: var(--t-dur);
    }
    &:active > .bg {
      opacity: .2;
    }
  }
</style>