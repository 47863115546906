import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/pages/home",
    children: [
      {
        path: "pages",
        component: () => import("./pages/authenticated/index.vue"),
        redirect: "/pages/home",
        children: [
          {
            path: "home",
            component: () => import("./pages/authenticated/home.vue"),
          },
          {
            path: "me",
            component: () => import("./pages/authenticated/me.vue"),
          },
          {
            path: "billing",
            component: () => import("./pages/authenticated/billing.vue"),
          },
          {
            path: "learn",
            component: () => import("./pages/authenticated/learn.vue"),
          },
          {
            path: "api",
            component: () => import("./pages/authenticated/api.vue"),
          },
          {
            path: "oauth2/authorize",
            component: () =>
              import("./pages/authenticated/oauth2/authorize.vue"),
          },
        ],
      },
      {
        path: "redirect",
        component: () => import("./pages/redirect.vue"),
      },
      {
        path: "onboarding",
        component: () => import("./pages/onboarding/index.vue"),
        meta: {
          afterEnter() {
            if (router.currentRoute.value.path === "/onboarding") {
              console.log("redirecting to login");
              router.push({
                path: "/onboarding/login",
                params: router.currentRoute.value.params,
                query: router.currentRoute.value.query,
                hash: router.currentRoute.value.hash,
              });
            }
          },
        },
        children: [
          {
            path: "confirm-email/:token",
            component: () =>
              import("./pages/onboarding/confirm-email/[token].vue"),
          },
          {
            path: "magic/:token",
            component: () => import("./pages/onboarding/magic/[token].vue"),
          },
          {
            path: "login",
            component: () => import("./pages/onboarding/login.vue"),
          },
          {
            path: "register",
            component: () => import("./pages/onboarding/register.vue"),
          },
          {
            path: "oidc-success",
            component: () => import("./pages/onboarding/oidc-success.vue"),
          },
          {
            path: "info",
            component: () => import("./pages/onboarding/info.vue"),
          },
          {
            path: "must-verify-email",
            component: () => import("./pages/onboarding/must-verify-email.vue"),
          },
          {
            path: "locked",
            component: () => import("./pages/onboarding/locked.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/pages/home",
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
