import { createI18n } from "vue-i18n";
import en from "../locales/en-US.json";
import de from "../locales/de-DE.json";

import { RemovableRef } from "@vueuse/core";
const avalableLocales = {
  "en-US": en,
  "de-DE": de,
};

let preferredLocale: RemovableRef<string> | null = null;
try {
  preferredLocale = useLocalStorage("locale", navigator.language || "en-US");
  const comparableLocales = Object.keys(avalableLocales);
  if (!comparableLocales.includes(preferredLocale.value)) {
    const similarLocale = comparableLocales.find((l) =>
      l.toUpperCase().startsWith(preferredLocale!.value.toUpperCase())
    );
    if (similarLocale) {
      preferredLocale.value = similarLocale;
    } else {
      preferredLocale.value = "en-US";
      preferredLocale.value = "en-US";
    }
  }
} catch {}

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: preferredLocale?.value,
  messages: avalableLocales,
  fallbackWarn: false,
  missingWarn: false,
});
