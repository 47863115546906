import { transitions } from "../assets/css/transitions";
import { router } from "../routes";

const tDuration = useMotion();
const defaultTransition = "fade";
const device = useDevice();
const mobile = computed(() => {
  return device.value === "mobile";
});

router.afterEach(async (to, from) => {
  if (to.path === from.path) return;
  const rule = findRule(from.path, to.path);
  // console.log("transition", from.path, to.path, rule);
  clear("pageTransition");
  const enter = rule?.enter?.(from.path, to.path) ?? defaultTransition;
  await importAndApply(enter, "enter");
  const exit = rule?.exit?.(from.path, to.path) ?? defaultTransition;
  await importAndApply(exit, "exit");
});

function findRule(fromPath?: string, toPath?: string) {
  return rules.find((rule) => {
    if (fromPath && !matchesPath(rule.from, fromPath)) {
      return false;
    }
    if (toPath && !matchesPath(rule.to, toPath)) {
      return false;
    }
    return true;
  });
}

function matchesPath(rulePath: string, actualPath: string) {
  const rule = rulePath.split("/");
  const actual = actualPath.split("/");
  if (rule.length > actual.length) return;
  for (let i = 0; i < actual.length; i++) {
    if (rule[i] !== actual[i]) {
      if (rule[i] === "*") {
        return true;
      }
      return false;
    }
  }
  return true;
}

async function importAndApply(name: string, mode: "enter" | "exit") {
  const css: string = transitions[`${mode}-${name}` as keyof typeof transitions];
  if (css) {
    apply("pageTransition", css);
  }
}

function apply(_class: string, css: string) {
  const styleTag = document.createElement("style");
  styleTag.className = _class;
  styleTag.innerHTML = css.replace("var(--t-dur)", `${tDuration.value}ms`);
  document.head.appendChild(styleTag);
}

function clear(_class: string) {
  const tags = document.getElementsByClassName(_class);
  for (let i = 0; i < tags.length; i++) {
    tags[i].remove();
  }
}

interface TransitionRule {
  from: string;
  to: string;
  enter?: (from: string, to: string) => string;
  exit?: (from: string, to: string) => string;
}

const indexPages = ["/pages/home", "/pages/me", "/pages/billing", "/pages/learn"];

const rules = [
  // {
  //   from: "/",
  //   to: "/onboarding",
  //   enter: () => "bluepicintro",
  // },
  {
    from: "/onboarding/login",
    to: "/onboarding/register",
    enter: () => "fromRight",
    exit: () => "toLeft",
  },
  {
    from: "/onboarding/register",
    to: "/onboarding/login",
    enter: () => "fromLeft",
    exit: () => "toRight",
  },
  {
    from: "/onboarding/login",
    to: "/onboarding/forgot",
    enter: () => "fromLeft",
    exit: () => "toRight",
  },
  {
    from: "/onboarding/forgot",
    to: "/onboarding/login",
    enter: () => "fromRight",
    exit: () => "toLeft",
  },
  {
    from: "/onboarding/confirm-email/*",
    to: "/onboarding/login",
    enter: () => "fromBottom",
    exit: () => "toTop",
  },
  {
    from: "/onboarding/*",
    to: "/",
    enter: () => "fromBottom",
    exit: () => "toTop",
  },
  {
    //catches everything. this should be last!!!
    from: "/pages/*",
    to: "/pages/*",
    enter: (from, to) => {
      if (from === to) return defaultTransition;
      const iFrom = indexPages.findIndex((p) => from === p);
      const iTo = indexPages.findIndex((p) => to === p);
      if (iFrom > iTo) {
        return mobile.value ? "fromLeft" : "fromTop";
      } else {
        return mobile.value ? "fromRight" : "fromBottom";
      }
    },
    exit: (from, to) => {
      if (from === to) return defaultTransition;
      const iFrom = indexPages.findIndex((p) => from === p);
      const iTo = indexPages.findIndex((p) => to === p);
      if (iFrom > iTo) {
        return mobile.value ? "toRight" : "toBottom";
      } else {
        return mobile.value ? "toLeft" : "toTop";
      }
    },
  },
] as TransitionRule[];
