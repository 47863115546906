import Hammer from 'hammerjs';
import { App } from 'vue';
//@ts-ignore - this is fine
delete Hammer.defaults.cssProps.userSelect;

const defaultRecognizers = [
  'tap', 
  'doubletap', 
  'pan', 
  'swipe', 
  'press', 
  'pinch', 
  'rotate'
];

const enabledRecognizers = [
  'tap',
  'doubletap',
  'press',
];

const diabledRecognizers = defaultRecognizers.filter((r) => !enabledRecognizers.includes(r));

const registeredHandlers = new Map<string, Map<string, (e: any) => void>>();

export function initHammer(app: App) {
  app.directive('hammer', (el, binding) => {
    if(el.hammer) {
      el.hammer.destroy();
    }
    var h = new Hammer(el);
    for(const r of diabledRecognizers) {
      h.get(r)?.set({ enable: false });
    }
    const ele = el as HTMLElement;
    let id = ele.getAttribute('v-hammer-el-id');
    if(!id) {
      id = registeredHandlers.size.toString();
      ele.setAttribute('v-hammer-el-id', id);
    }
    if(!registeredHandlers.has(id)) {
      registeredHandlers.set(id, new Map<string, (e: any) => void>());
    }
    const handlers = registeredHandlers.get(id);
    if(!binding.arg) return;
    registeredHandlers.get(id)?.set(binding.arg, binding.value);
    h.on(enabledRecognizers.join(' '), (ev) => {
      handlers?.get(ev.type)?.(ev);
    });
    el.hammer = h;
  })
}