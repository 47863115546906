export type BaseActions = {
  _hydrate?: () => void;
}

export const pinia = createPinia();
const initializedStores = new Set<string>();
watch(pinia.state, (nv, ov) => {
  const _p = getActivePinia();
  if(!_p) return;
  for(const id in nv) {
    if(initializedStores.has(id)) continue;
    //@ts-ignore - this is fine 🐕🔥
    const store = _p._s?.get(id);
    if(!store) continue;
    // console.log(`hydrating ${id} store`);
    store._hydrate?.();
    initializedStores.add(id);
  }
}, { deep: true, immediate: true });
