{
  "email": "E-Mail Address",
  "password": "Password",
  "username": "Username",
  "consent": {
    "agree": "By registering you agree to the",
    "terms": "Terms and Conditions",
    "and": "and",
    "privacy": "Privacy Policy",
    "suffix": ""
  },
  "password-rating": {
    "weak": "Weak",
    "medium": "medium",
    "strong": "strong",
    "tooWeak": "too weak"
  },
  "error": "Something went wrong...",
  "errors": {
    "ERR_AUTH_1": "You don't have permission to create an apikey for this user",
    "ERR_AUTH_2": "Apikey expiry cannot be more than 1 year in the future",
    "ERR_AUTH_3": "You do not have permission to create multi-use apikeys",
    "ERR_AUTH_4": "You don't have permission to create a magic link for this user",
    "ERR_AUTH_5": "Apikey not found",
    "ERR_AUTH_6": "You don't have permission to view this user's apikeys",
    "ERR_AUTH_7": "You don't have permission to delete this apikey",
    "ERR_AUTH_9": "User is locked. Check your E-Mail.",
    "ERR_AUTH_10": "User not found",
    "ERR_AUTH_11": "Invalid key",
    "ERR_AUTH_12": "You took too long to enter the code.",
    "ERR_AUTH_13": "E-Mail confirmation not found",
    "ERR_AUTH_14": "E-Mail confirmation expired",
    "ERR_AUTH_15": "Error confirming E-Mail",
    "ERR_AUTH_16": "User is not allowed to invite to this team",
    "ERR_AUTH_17": "User is not allowed to redeem this invite",
    "ERR_AUTH_18": "Invite not found",
    "ERR_AUTH_19": "User is not allowed to read invites",
    "ERR_AUTH_20": "User is not allowed to revoke this invite",
    "ERR_AUTH_21": "User is not allowed to decline this invite",
    "ERR_AUTH_22": "You are not allowed to get metadata for this user",
    "ERR_AUTH_23": "You are not allowed to set metadata for this user",
    "ERR_AUTH_24": "Metadata not found",
    "ERR_AUTH_25": "You do not have permission to get this users permissions",
    "ERR_AUTH_26": "Reset not found",
    "ERR_AUTH_27": "Reset expired",
    "ERR_AUTH_28": "You cannot delete another user session",
    "ERR_AUTH_29": "You cannot view this user's sessions",
    "ERR_AUTH_30": "You are not allowed to create a team",
    "ERR_AUTH_31": "You are not allowed to create a subteam for this team",
    "ERR_AUTH_33": "Team name already exists in your organization",
    "ERR_AUTH_35": "You are not allowed to get all teams",
    "ERR_AUTH_36": "Team not found",
    "ERR_AUTH_37": "User is already admin",
    "ERR_AUTH_38": "You can't add admins to this team",
    "ERR_AUTH_39": "User is not admin",
    "ERR_AUTH_40": "You cannot remove the last admin",
    "ERR_AUTH_41": "You can't remove admins from this team",
    "ERR_AUTH_42": "User is already member",
    "ERR_AUTH_43": "User is not a member",
    "ERR_AUTH_44": "You can't remove members from this team",
    "ERR_AUTH_45": "User is an admin",
    "ERR_AUTH_46": "You are not allowed to lock this user",
    "ERR_AUTH_47": "Team is not locked",
    "ERR_AUTH_48": "You are not allowed to edit this team",
    "ERR_AUTH_49": "Team with this name already exists",
    "ERR_AUTH_50": "You are not allowed to edit team members",
    "ERR_AUTH_51": "You are not allowed to edit team themes",
    "ERR_AUTH_52": "You are not allowed to change this teams passthroughPermissions",
    "ERR_AUTH_53": "You can only pass through permissions that are already present on the team",
    "ERR_AUTH_54": "You are not allowed to change this teams permissions",
    "ERR_AUTH_55": "You are not allowed to delete this team",
    "ERR_AUTH_56": "You can not delete a team that has children",
    "ERR_AUTH_57": "Dummy users cannot have TOTP enabled",
    "ERR_AUTH_58": "A user with this E-Mail already exists",
    "ERR_AUTH_59": "A dummy user must be assigned to a team",
    "ERR_AUTH_60": "Passwords must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter and one number",
    "ERR_AUTH_61": "You can only create dummy users for your own team",
    "ERR_AUTH_62": "You are not allowed to unlock this user",
    "ERR_AUTH_63": "You are not allowed to remove this user",
    "ERR_AUTH_64": "no users found",
    "ERR_AUTH_67": "dummy users cannot use 2fa",
    "ERR_AUTH_68": "no new totp secret to confirm",
    "ERR_AUTH_69": "invalid token",
    "ERR_AUTH_70": "You are not allowed to edit this user",
    "ERR_AUTH_72": "You are not allowed to edit max sessions",
    "ERR_AUTH_73": "You are not allowed to change this users permissions",
    "ERR_AUTH_74": "You are not allowed to change this users password",
    "ERR_AUTH_76": "No new E-Mail to confirm",
    "ERR_AUTH_79": "value is required",
    "ERR_AUTH_80": "Apikey expired",
    "ERR_AUTH_81": "Admins and members must not intersect",
    "ERR_AUTH_82": "Profile is disabled",
    "ERR_AUTH_83": "Invalid key",
    "ERR_AUTH_84": "Invitation is required during closed beta",
    "ERR_AUTH_85": "User is not an affiliate",
    "ERR_AUTH_86": "Profile is disabled",
    "ERR_AUTH_87": "No auth header provided",
    "ERR_AUTH_88": "Invalid auth header",
    "ERR_AUTH_89": "Invalid API key",
    "ERR_AUTH_90": "Invalid Session",
    "ERR_AUTH_92": "Invalid OIDC Token",
    "ERR_AUTH_93": "Custom price not found",
    "ERR_AUTH_94": "Custom price is used by teams",
    "ERR_AUTH_95": "Subscription not found",
    "ERR_AUTH_96": "Customer not found",
    "ERR_AUTH_97": "Choose your own price not allowed",
    "ERR_AUTH_98": "Team not found",
    "ERR_AUTH_99": "Subteams cannot have their own subscriptions",
    "ERR_AUTH_100": "User is not team owner",
    "ERR_AUTH_101": "Price could not be generated",
    "ERR_AUTH_102": "Could not create subscription",
    "ERR_AUTH_103": "Invalid custom price expression",
    "ERR_AUTH_104": "User is already a member of this team",
    "ERR_AUTH_105": "You cannot set metadata for both user and team",
    "ERR_AUTH_106": "Unrecognised issuer",
    "ERR_AUTH_107": "Not implemented",
    "ERR_AUTH_108": "You are not allowed to create a team without subscription",
    "ERR_AUTH_109": "You are not allowed to hard-delete this team",
    "ERR_AUTH_110": "You are not allowed to edit this teams subscription",
    "ERR_AUTH_111": "You are not allowed to check this teams subscription",
    "ERR_AUTH_112": "Only root teams can have subscriptions",
    "ERR_AUTH_113": "No token",
    "ERR_AUTH_114": "No E-Mail in token",
    "ERR_AUTH_115": "Invalid OIDC ID Token",
    "ERR_AUTH_116": "E-Mail is required for non-dummy users",
    "ERR_AUTH_117": "This user is not part of this team",
    "ERR_AUTH_118": "no E-Mail",
    "ERR_AUTH_119": "ID is already associated with an account",
    "ERR_AUTH_120": "ID not found"
  },
  "night": "Night",
  "morning": "Morning",
  "afternoon": "Afternoon",
  "evening": "Evening",
  "feed-type": {
    "you": {
      "COLLECTION_CREATED": "created a Post",
      "COLLECTION_UPDATED": "updated a Post",
      "COLLECTION_DELETED": "deleted a Post",
      "COMMENT": "commented"
    },
    "they": {
      "COLLECTION_CREATED": "created a Post",
      "COLLECTION_UPDATED": "updated a Post",
      "COLLECTION_DELETED": "deleted a Post",
      "COMMENT": "commented"
    }
  },
  "already-have-account": "You already have an account?",
  "back-to-sign-in": "back to sign in",
  "confirm-success": "Thanks for confirming your E-Mail address!",
  "confirming-email": "Confirming your E-Mail address...",
  "must-confirm-email": "You must confirm your E-Mail address before you can sign in.",
  "consent-required": "Consent is required",
  "do-sign-in": "Sign in",
  "do-sign-up": "Sign up",
  "dont-have-an-account": "Don't have an account?",
  "email-invalid": "E-Mail Address is invalid",
  "email-required": "E-Mail Address is required",
  "email-resent": "E-Mail re-sent",
  "forgot-password": "Forgot your password?",
  "forgot-text": "Enter your E-Mail address and we'll send you a link to reset your password.",
  "invalid-confirm-token": "Invalid confirmation link. E-Mail Address either wasn't found or has already been confirmed.",
  "invalid-magic-link": "invalid Sign-In Link",
  "new-password": "New password",
  "password-invalid": "password doesn't match requirements",
  "password-required": "Password required",
  "password-strength": "Password strength",
  "passwords-dont-match": "Passwords don't match",
  "redirecting-to-sign-in": "Redirecting you to the Sign in Page...",
  "register-success": "Now you just need to confirm your E-Mail address. Check your mailbox.",
  "remember-me": "Remember me",
  "repeat-password": "Repeat password",
  "resend-email": "resend",
  "reset-password": "Reset password",
  "return-to-sign-in": "Return to sign in",
  "send-email": "Send E-Mail",
  "send-sign-in-link": "Send me a Sign-In Link",
  "sign-in": "Sign in",
  "sign-in-link": "Sign-In Link",
  "sign-up": "Sign up",
  "this-shouldnt-happen": "this shouldn't happen. Please report this to the developers.",
  "you-can-close-this-tab": "You can close this tab now",
  "workspace-is-required": "Workspace is required",
  "title-is-required": "Title is required",
  "collection-saved": "Post saved",
  "editor": "Editor",
  "save-to-collection": "Save to Post",
  "space": "Space",
  "select-a-workspace": "Select a workspace",
  "title": "Title",
  "text": "Text",
  "save": "Save",
  "gallery": "Gallery",
  "signing-in": "Signing in",
  "email-sent": "E-Mail sent",
  "magic-link-sent": "We sent you a link, that will automagically sign you in. Check your mailbox",
  "invite-redeemed": "Invite redeemed",
  "invite-sent": "Invite sent",
  "invites": "Invites",
  "only-redeemed": "Only redeemed",
  "success": "Success",
  "invites-sent-successfully": "Invites sent successfully",
  "invites-could-not-be-sent": "Invites could not be sent",
  "failed-invites": "Failed Invites:",
  "retry": "Retry",
  "invite-sent-successfully": "Invite sent successfully",
  "invite-will-be-sent": "Invite will be sent",
  "disabled": "Disabled",
  "get-invite": "Get Invite",
  "edit": "Edit",
  "delete-api-key": "Delete API key",
  "are-you-sure-this-action-cannot-be-undone": "Are you sure? This action cannot be undone",
  "yes": "Yes",
  "cancel": "Cancel",
  "expires": "expires:  {0}",
  "uses-apikey": "uses: {0}{1}",
  "api-keys": "API Keys",
  "copied-to-clipboard": "Copied to clipboard",
  "delete-collection": "Delete Post",
  "confirm-permanently-delete-collection": "Are you sure you want to permanently delete this Post? This is irreversible!",
  "im-sure": "I'm sure",
  "collection-deleted": "Post deleted",
  "created-created": "created: {0}",
  "last-edit-lastedit": "last edit: {0}",
  "created-by-creator-name": "created by {0}",
  "no-field-for-datatype-type-properties-modelvalue": "No field for datatype \"{0}\" {1} {2}",
  "field-not-found": "Field not found",
  "editor-warning.title": "Beta Version!",
  "editor-warning.content": "This is an early-stage beta version. We highly recommend to use Google Chrome instead of Safari, Firefox or Edge at this time to ensure everyrthing looking and working as you expect. Full-browser support is on our list and will be there on release.",
  "editor-warning.confirm": "Ok, I understand",
  "download": "Download",
  "share": "Share",
  "reset-view": "Reset View",
  "feed": "Feed",
  "incoming-invites": "Incoming Invites:",
  "invite-accepted": "Invite accepted!",
  "invite-declined": "Invite declined!",
  "invited-by-inviter-name": "invited by {0}",
  "pending-invites": "Pending Invites:",
  "invite-resent": "Invite resent",
  "invite-revoked": "Invite revoked",
  "resend": "Resend",
  "revoke": "Revoke",
  "spaces": "Spaces",
  "settings": "Settings",
  "key-is-required": "Key is required",
  "name-cannot-contain-spaces": "Name cannot contain spaces",
  "value-is-required": "Value is required",
  "metadata-created": "Metadata created",
  "metadata": "Metadata",
  "set-metadata": "Set Metadata",
  "key": "Key",
  "value": "Value",
  "create": "Create",
  "delete-metadata": "Delete Metadata",
  "are-you-sure-you-want-to-delete-this-metadata-entry": "Are you sure you want to delete this metadata entry?",
  "metadata-deleted": "Metadata deleted",
  "delete": "Delete",
  "only-png-and-jpeg-files-are-allowed": "Only PNG and JPEG files are allowed",
  "upload": "Upload",
  "zoom-and-crop": "Zoom & Crop",
  "finish": "Finish",
  "profile-picture-updated": "Profile picture updated",
  "change-picture": "Change Picture",
  "zoom": "Zoom",
  "tap-here-to-upload": "Tap here to upload",
  "click-or-drag-a-file-to-this-area-to-upload": "Click or drag a file to this area to upload",
  "max-16mb-allowed-png-jpg-jpeg": "Max. 20MB, Allowed: .png .jpg .jpeg",
  "kill-session": "Kill session",
  "are-you-sure-you-will-be-logged-out-on-this-device": "Are you sure? You will be logged out on this device",
  "ip-session-ip": "IP: {0}",
  "last-seen-lastseen": "Last seen: {0}",
  "kill-all-sessions": "Kill all sessions",
  "are-you-sure-you-will-be-logged-out-on-all-other-devices": "Are you sure? You will be logged out on all other devices",
  "sessions": "Sessions",
  "close-all-other-sessions": "close all other sessions",
  "name-is-required": "Name is required",
  "team-created": "Team Created",
  "create-sub-team": "Create Sub-Team",
  "name": "Name",
  "email-is-required": "E-Mail is required",
  "invite-user": "Invite User",
  "select-team": "Select Team",
  "none": "None",
  "no-category": "No Category",
  "search": "Search",
  "failed-to-update-user": "Failed to update user",
  "share-with-sub-teams": "share with sub-teams",
  "admin-only": "Admin Only",
  "select-variant": "Select variant:",
  "edit-theme": "Edit Theme",
  "reset": "Reset",
  "primary": "Primary",
  "background": "Background",
  "greyscale-background": "Greyscale Background",
  "advanced": "Advanced",
  "secondary": "Secondary",
  "background-1": "Background 1",
  "background-2": "Background 2",
  "foreground-1": "Foreground 1",
  "foreground-2": "Foreground 2",
  "borders": "Borders",
  "warning": "Warning",
  "error-0": "Error",
  "roundness": "Roundness",
  "font": "Font",
  "background-gradient": "Background Gradient",
  "leave": "Leave",
  "demote": "Demote",
  "remove": "Remove",
  "promote": "Promote",
  "resend-confirmation-email": "resend confirmation E-Mail",
  "bluepic-admin": "Bluepic Admin",
  "min-8-characters": "min. 8 characters*",
  "lower-case": "lower case",
  "upper-case": "upper case",
  "digits": "digits",
  "special-characters": "special characters",
  "profile-updated-successfully": "Profile updated successfully",
  "comment": "Comment",
  "standalone-link": "Standalone Link:",
  "create-profile": "Create Profile",
  "delete-account": "Delete account",
  "confirm-delete-account": "Are you sure you want to delete your account? For your own privacy, this is permanent and cannot be undone.",
  "are-you-absolutely-sure": "Are you absolutely sure?",
  "last-chance-to-keep-account": "This is your last chance to cancel. Once you delete your account, it is gone forever.",
  "offboarding": "Thank you for using our service. All your data has been deleted permanently. You will be logged out now...",
  "profile-picture": "Profile Picture",
  "delete-user": "Delete User",
  "left-team": "Left team",
  "parent": "Parent",
  "leave-team": "Leave Team",
  "you-cant-leave-if-youre-the-only-admin": "You can't leave if you're the only admin",
  "saved": "Saved",
  "delete-team": "Delete Team",
  "confirm-delete-team": "Are you sure you want to delete this team? This action cannot be undone.",
  "team-deleted": "Team deleted",
  "edit-templates": "Edit Templates",
  "change": "change",
  "theme": "Theme",
  "preview": "Preview",
  "your-teams": "Your Teams:",
  "create-team": "Create Team",
  "appearance": "General",
  "language": "Language",
  "font-size": "Font Size",
  "notifications": "Notifications",
  "me": "Me",
  "admin": "Admin",
  "affiliate": "Affiliate",
  "teams": "Teams",
  "templates": "Templates",
  "billing": "Billing",
  "my-templates": "My Templates",
  "my-teams": "My Teams",
  "log-out": "Log Out",
  "confirm-logout": "Are you sure you want to sign out?",
  "content": "Content",
  "fontsize": {
    "small": "small",
    "medium": "medium",
    "large": "large"
  },
  "livemode": {
    "auto": "Auto",
    "always": "Always",
    "never": "Never"
  },
  "editor-live-preview": "Editor live preview",
  "live-preview-help": "Changes made in the editor will be immediately visible. May lead to performace issues on some devices.",
  "rendering": "rendering",
  "drafts": "Drafts",
  "s-space": "'s Space",
  "draft": "Draft",
  "found-drafts": "Found drafts:",
  "start-fresh": "Start fresh",
  "only-unredeemed": "Only unredeemed",
  "redeemed-invites": "Redeemed invites:",
  "you": "you",
  "email-confirmed": "E-Mail confirmed",
  "email-not-confirmed": "E-Mail not yet confirmed",
  "organization": "Organization",
  "organization-shorthand": "Org",
  "email-template": "E-Mail Template",
  "monthly": "Monthly",
  "month": "Month",
  "yearly": "Yearly",
  "year": "Year",
  "current-plan": "Current plan",
  "free": "Free",
  "pro": "Pro",
  "enterprise": "Enterprise",
  "switch-to-yearly-billing": "Switch to yearly billing",
  "switch-to-monthly-billing": "Switch to monthly billing",
  "billing-contact": "Billing Contact",
  "phone": "Phone",
  "street": "Address line 1",
  "address-line-2": "Address Line 2",
  "zip": "Postal Code",
  "city": "City",
  "state": "State",
  "invoices": "Invoices",
  "no-invoices-found": "No invoices found",
  "open": "Open",
  "paid": "Paid",
  "uncollectible": "Uncollectible",
  "void": "Void",
  "pay": "Pay",
  "load-more": "Load More",
  "payment-methods": "Payment Methods",
  "no-payment-methods-found": "No payment methods found",
  "default": "Default",
  "add-payment-method": "Add Payment Method",
  "delete-payment-method": "Delete Payment Method",
  "are-you-sure-you-want-to-delete-this-payment-method": "Are you sure you want to delete this payment method?",
  "confirm": "Confirm",
  "subscriptions": "Subscriptions",
  "per": "per",
  "cancel-subscription": "Cancel Subscription",
  "are-you-sure-you-want-to-cancel-this-subscription": "Are you sure you want to cancel this subscription?",
  "expiration-date": "Expiration date",
  "phone-is-required": "Phone is required",
  "address-line-1-is-required": "Address line 1 is required",
  "postal-code-is-required": "Postal code is required",
  "city-is-required": "City is required",
  "state-is-required": "State is required",
  "fast": "fast",
  "slow": "slow",
  "transition-speed": "Transition Speed",
  "you-dont-have-permission-for-this-template": "You don't have permission for this template.",
  "are-you-in-the-right-team": "Are you in the right team?",
  "copy-link": "Copy link",
  "invalid-invite": "Invalid invite",
  "you-are-not-the-intended-recipient-of-this-invite": "You are not the intended recipient of this invite.",
  "you-have-been-invited-to-join": "You have been invited to join:",
  "by": "by",
  "accept": "Accept",
  "decline": "Decline",
  "pro-badge": "PRO",
  "next-payment": "Next payment",
  "cancel-subscription-short": "cancel",
  "keep-it": "keep it",
  "subscription-canceled": "Subscription canceled",
  "switch-to-pro": "Switch to pro",
  "switch-to-free": "Switch to free",
  "free-forever": "Free forever",
  "professional": "Professional",
  "pro-header": "Professional - 5€ / month",
  "pro-subtitle": "For independent content creators",
  "unlimited-projects": "unlimited projects",
  "5-personal-templates": "5 personal templates",
  "unlimited-personal-templates": "unlimited personal templates",
  "full-access-to-learning-material": "full access to learning material",
  "early-access-to-new-template-collections": "early access to new template collections",
  "cancel-pro-subscription": "Are you sure you want to cancel your pro subscription? You will lose access to all pro features. Your excess personal templates will be deleted.",
  "running-out": "Running out",
  "reactivate": "Reactivate",
  "delete-all": "delete all",
  "are-you-sure-you-want-to-delete-all-drafts-this-cannot-be-undone": "Are you sure you want to delete all drafts? This cannot be undone.",
  "confirm-purchase": "Confirm Purchase",
  "renews-automatically": "renews automatically",
  "coupon": "Coupon",
  "apply": "Apply",
  "subtotal": "Subtotal",
  "vat": "VAT",
  "total": "Total",
  "shared": "shared",
  "upcoming-payments": "Upcoming payments:",
  "all-subscriptions": "All subscriptions",
  "deleted-user": "deleted user",
  "visible": "visible",
  "hidden": "hidden",
  "failed-to-update-team": "Failed to update team",
  "mixed": "mixed",
  "enabled": "enabled",
  "minimal": "minimal",
  "medium": "medium",
  "extra": "extra",
  "coupon-applied": "Coupon applied",
  "active": "Active",
  "make-default": "Make default",
  "no-subscription": "no subscription",
  "owner": "owner",
  "sub_inactive": "Subscription is inactive",
  "sub_noSubscription": "We could not find a subscription for this team",
  "sub_noPayment": "This subscription was not paid for",
  "sub_notEnoughSlots": "You have more members than your subscription allows",
  "sub_notEnoughDepth": "You have more subteam layers than your subscription allows",
  "sub_canceled": "Subscription canceled",
  "subscription": "Subscription",
  "action-required": "Action required",
  "pay-now": "Pay now",
  "subscribe-now": "Subscribe now",
  "upgrade-subscription-now": "Upgrade subscription now",
  "slots": "Slots",
  "hierarchy-layers": "Subteam Layers",
  "incl-tax": "incl. Tax",
  "tax": "Tax",
  "incl": "incl",
  "excl": "excl",
  "payment-interval": "Payment interval",
  "hard-delete-team": "HARD-Delete Team",
  "edit-subscription": "Edit subscription",
  "slots-are-required": "Slots are required",
  "minimum-status-value-totalmembers-5-slots": "Minimum {0} slots",
  "maximum-300-slots": "Maximum 300 slots",
  "hierarchy-layers-are-required": "Subteam layers are required",
  "minimum-status-value-actualdepth-1-layer": "Minimum {0} layer",
  "maximum-1-layer": "Maximum 1 layer",
  "you-need-to-add-a-payment-method-before-you-can-purchase-a-subscription": "You need to add a payment method before you can purchase a subscription.",
  "all-users": "All Users",
  "all-teams": "All Teams",
  "custom-prices": "Custom Prices",
  "admin_prices": "Custom Prices",
  "admin_users": "All Users",
  "admin_teams": "All Teams",
  "admin_feature_flags": "Feature flags",
  "no-custom-prices-found": "No custom prices found",
  "create-custom-price": "Create custom price",
  "price": "Price",
  "update": "Update",
  "expression": "Expression",
  "expression-is-required": "Expression is required",
  "javascript-expression": "Javascript Expression",
  "try-it-out": "Try it out",
  "choose-your-own-price": "Choose your own price",
  "user-ids": "User ids",
  "search-users-by-email": "Search users by E-Mail",
  "invalid-expression": "Invalid expression",
  "users": "Users",
  "no-subscription-needed": "No subscription needed",
  "custom-price": "Custom Price",
  "min-newprice": "(min. {0})",
  "select": "Select",
  "admin-settings": "Admin Settings",
  "use-webgl-when-available": "use WebGL when available",
  "experimental": "experimental",
  "uploading": "Uploading",
  "normalizing": "Normalizing",
  "checking-permission": "Checking permission",
  "upload-failed": "Upload Failed",
  "please-let-us-know-if-this-keeps-happening": "Please let us know if this keeps happening.",
  "image-file-size-is-too-big": "Image file size is too big.",
  "image-file-is-corrupted": "Image file is corrupted.",
  "onboarding": "Onboarding",
  "start": "Start",
  "resume": "Resume",
  "learn-to-use-bluepic-social": "Learn how to use Bluepic Social",
  "reset-progress": "Reset progress",
  "reset-all": "Reset all",
  "reset-all-progress": "Reset all progress",
  "are-you-sure-you-want-to-reset-all-progress": "Are you sure you want to reset all progress?",
  "feature-flags": "Feature flags",
  "nothing-to-see-here": "Nothing to see here",
  "continue-with": "Continue with:",
  "rate-limit-exceeded-please-try-again-in-a-few-minutes": "Rate limit exceeded. Please try again in a few minutes.",
  "unauthorized": "Unauthorized",
  "connected-accounts": "Connected Accounts",
  "connected-accounts-num-0": "Not connected",
  "connected-accounts-num-1": "1 connection",
  "connected-accounts-num-x": "{0} connections",
  "add-connection": "Add connection",
  "remove-connection": "Remove Connection",
  "are-you-sure-you-want-to-remove-this-connection": "Are you sure you want to remove this connection?",
  "or": "or",
  "login-successful": "Login successful",
  "you-can-close-this-window-now": "You can close this window now",
  "contact-us": "Contact us",
  "login-failed": "Login failed",
  "invalid-email-or-password": "Invalid E-Mail or password",
  "you-cant-leave-if-youre-the-owner": "You can't leave if you're the owner",
  "edit-billing-contact": "Edit billing contact",
  "country": "Country",
  "country-is-required": "Country is required",
  "incomplete": "Incomplete",
  "please-complete-your-billing-information": "Please complete your billing information.",
  "reactivate-subscription": "reactivate subscription",
  "select-profile": "Select profile",
  "please-wait": "please wait",
  "credit": "Credit",
  "send-invite": "Send invite",
  "reset-to-default": "Reset to default",
  "contrast-between-primary-and-background-is-low": "Contrast between primary and background is low!",
  "failed-to-load-image": "Failed to load image",
  "sign-out": "Sign out",
  "manage-billing": "Manage billing",
  "balance": "Balance",
  "email-not-verified": "E-Mail not verified",
  "device-model": "device model",
  "browser": "browser",
  "you-can-only-upload-files-up-to-10mb": "you can only upload files up to 20mb",
  "something-went-wrong": "something went wrong...",
  "cropping": "Cropping",
  "compressing": "Compressing",
  "saving": "Saving",
  "redirecting-to-target-href": "Redirecting to {0} ...",
  "no-returnurl-specified": "No returnurl specified",
  "home": "Home",
  "user-settings": "Settings",
  "client-apps": "Client Apps",
  "client-apps-description": "Create a client app to grant programmatically access to your bluepic account",
  "api": "API",
  "api-description": "Bluepic API",
  "welcome-to-bluepic": "Welcome to Bluepic",
  "apps": "Apps",
  "app-headline-studio": "Create graphic templates for your brand in STUDIO",
  "app-headline-social": "Fill your templates with content in SOCIAL",
  "app-headline-embed": "Embed a graphic generator on any website or app",
  "recommended": "recommended",
  "selected-tutorials": "Selected videos",
  "useful-links": "Useful links",
  "tutorials-documentation": "Tutorials / Documentation",
  "support": "Support",
  "do-open": "open",
  "email-0": "E-Mail",
  "app": "App",
  "settings-saved": "Settings saved",
  "notify-me-when": "Notify me when...",
  "via": "via",
  "SPACE_EVENTS": "something happened in a space (members added/removed, owner changed, etc.)",
  "TEMPLATES": "templates in a space are changed",
  "INVITES": "I got invited to join a space",
  "ACCOUNT_EVENTS": "my account was changed (password, E-Mail, etc.)",
  "onboarding-title": "Welcome to Bluepic",
  "onboarding-subtitle": "Create smart graphic templates",
  "cinfo-step1-p2": "Please tell us your name so we know how to address you.",
  "cinfo-step2-p1": "Welcome",
  "cinfo-step2-p2": "Please tell us the name of your company and your website so we can create your workspace.",
  "cinfo-step3-p1": "Before you start...",
  "cinfo-step3-p2": "Please tell us how you heard about Bluepic. This helps us a lot 🙏",
  "wo-hast-du-von-bluepic-erfahren": "Where did you hear about Bluepic?",
  "done": "Done",
  "continue": "Continue",
  "company-name": "Company name",
  "company-website": "Company website",
  "ref-search": "Search Engine",
  "ref-recommendation": "Recommendation",
  "ref-social": "Social Media",
  "ref-embed": "Embedded template on another website",
  "ref-ad": "Advertisement",
  "ref-other": "Other",
  "learn-bluepic": "Learn Bluepic",
  "learn-bluepic-description": "Instructions, video tutorials and more",
  "home-headline": "Start creating smart graphic templates",
  "app-cta-studio": "Create templates",
  "app-cta-social": "Share templates",
  "app-cta-embed": "Embed templates",
  "onboarding-progress": "Onboarding Progress",
  "back": "Back",
  "skip": "Skip",
  "redirecting": "Redirecting you...",
  "resend-confirmation-success": "Confirmation email resent",
  "thanks-for-signup": "Thank you for signing up!",
  "appsumo-license-claimed-successfully": "AppSumo license claimed successfully",
  "wrong-account": "Wrong account?",
  "claim": "Claim",
  "your-appsumo-lifetime-purchase-is-valid-and-ready-for-you": "Your AppSumo lifetime license is valid and ready for you! 🥳",
  "APPSUMO_SOCIAL": "AppSumo SOCIAL Credits",
  "APPSUMO_EMBED": "AppSumo EMBED Credits",
  "APPSUMO_API": "AppSumo API Credits",
  "DAILY": "Daily",
  "WEEKLY": "Weekly",
  "MONTHLY": "Monthly",
  "YEARLY": "Yearly"
}
