import { Ref } from "vue";
import { initRemoteConfig } from "../util/remoteConfig";

export const remoteConfigKeys = ["FEATURE_FLAGS"] as const;

export type RemoteConfig = {
  [key in (typeof remoteConfigKeys)[number]]: string;
};

export function useConfig() {
  const fetching = ref(false);
  const config = ref<RemoteConfig>();
  async function refresh() {
    return new Promise<RemoteConfig>((resolve, reject) => {
      fetching.value = true;
      initRemoteConfig({
        host: import.meta.env.V_CONFIG_BASE_URL,
        project: "Bluepic",
        application: "ID",
        environment: import.meta.env.V_ENV,
        expectedKeys: remoteConfigKeys,
        onUpdate(key: string, value: any) {
          if (config.value && remoteConfigKeys.includes(key as any)) {
            config.value[key as keyof RemoteConfig] = value;
          }
        },
      })
        .then((_config: RemoteConfig) => {
          config.value = _config;
          resolve(_config);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        })
        .finally(() => {
          fetching.value = false;
        });
    });
  }
  if (!config.value && !fetching.value) {
    refresh();
  }
  return {
    fetchingConfig: fetching,
    config,
    refreshConfig: refresh,
  };
}
