import Color from 'color';

export interface HSL {
  h: number;
  s: number;
  l: number;
}

export function getHSL(color: string) {
  const hsl = Color(color).hsl().array();
  return {
    h: hsl[0],
    s: hsl[1],
    l: hsl[2],
  } as HSL;
}

export interface HSLModifier {
  h?: (v: number) => number;
  s?: (v: number) => number;
  l?: (v: number) => number;
}


export function hslModify(color: string, modifier: HSLModifier) {
  const hsl = getHSL(color);
  return Color({
    h: modifier.h?.(hsl.h) ?? hsl.h,
    s: modifier.s?.(hsl.s) ?? hsl.s,
    l: modifier.l?.(hsl.l) ?? hsl.l,
  }).hexa();
}