import { DebounceSettings, ThrottleSettings } from "lodash";
import { Ref, watch, WatchCallback, WatchOptions, WatchStopHandle, WatchSource,  } from "vue";

declare type MapSources<T, Immediate> = {
  [K in keyof T]: T[K] extends WatchSource<infer V> ? Immediate extends true ? V | undefined : V : T[K] extends object ? Immediate extends true ? T[K] | undefined : T[K] : never;
};

export function oneWaySync(target: Ref, source: Ref) {
  target.value = source.value;
  watch(source, (value) => {
    target.value = value;
  });
}

export function _watch<T extends WatchSource[], Immediate extends Readonly<boolean> = false>(
  sources: [...T], 
  cb: WatchCallback<MapSources<T, false>, MapSources<T, Immediate>>, 
  options?: WatchOptions<Immediate> & { 
    debounce?: {
      wait?: number;
    } & DebounceSettings, 
    throttle?: {
      wait?: number;
    } & ThrottleSettings,
  }
): WatchStopHandle {
  if(options?.debounce) {
    const { wait, ...settings } = options.debounce;
    cb = debounce(cb, wait, settings);
  } else if(options?.throttle) {
    const { wait, ...settings } = options.throttle;
    cb = throttle(cb, wait, settings);
  }
  return watch(sources, cb, options);
}